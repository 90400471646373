import React from "react";
import { Link } from "react-router-dom";

const electricChargerTitle = 'CARGADORES DE VEHÍCULO ELÉCTRICOS:\n TRÁMITES, SUMINISTRO E INSTALACIÓN'

const services = [
    {src:'/projects-diseno-tramites-planos.jpg', alt:'Servicio de diseño eléctrico y trámites de planos visados en el CFIA', 
    title:'DISEÑO ELÉCTRICO Y TRÁMITES DE PLANOS VISADOS EN EL CFIA', link: '/Servicios/Diseno-electrico-tramites-planos'},

    {src:'/services-diagnostico-electrico.jpg', alt:'Servicio de diagnóstico de instalaciones eléctricas', title:'DIAGNÓSTICO DE INSTALACIONES ELÉCTRICAS',
    link:'/Servicios/Diagnostico-instalaciones-eléctricas'},

    {src:'/services-medicion-calidad-energia2.jpg', alt:'Servicio de diagnóstico de medición de calidad de energía', title:'MEDICIÓN DE CALIDAD DE ENERGÍA',
    link:'/Servicios/Medicion-calidad-energia'},

    {src:'/projects-diagnostico-pararrayos.jpg', alt:'Servicio de diagnóstico de pararrayos y mallas de puesta a tierra',
    title:'DIAGNÓSTICO DE PARARRAYOS Y MALLAS DE PUESTA A TIERRA', link:'/Servicios/Pararrayos-mallas-puesta-tierra'},

    {src:'/services-torre-comunicaciones.jpg', alt:'Servicio de mantenimiento de torres de comunicaciones',title:'MANTENIMIENTO DE TORRES DE COMUNICACIONES', 
    link:'/Servicios/Torres-comunicaciones'},

    {src:'/services-cons-y-mantenimiento.jpg', alt:'Servicio de construcción y mantenimiento de instalaciones eléctricas y electromecánicas comerciales e industriales',
    title:'CONSTRUCCIÓN Y MANTENIMIENTO DE INSTALACIONES ELÉCTRICAS Y ELECTROMECÁNICAS COMERCIALES E INDUSTRIALES', 
    link:'/Servicios/Construccion-mantenimiento-instalaciones-electricas-electromecanicas'},

    {src:'/services-cargador-electrico.jpg', alt:'Servicio de cargadores de vehículo eléctricos: trámites, suministro e instalación', title: electricChargerTitle, 
    link:'/Servicios/Cargadores-vehiculos-electricos'},

    {src:'/services-sistemas-fotovoltaicos.jpg', alt:'Servicio de diseño y construcción de sistemas fotovoltaicos', title:'DISEÑO Y CONSTRUCCIÓN DE SISTEMAS FOTOVOLTAICOS', 
    link:'/Servicios/Sistemas-fotovoltaicos'}
]

function FillRows (props) {
    return (
        <div className="flex flex-col items-center gap-y-4 lg:w-[700px] w-full mx-6">
            <Link to={props.link}>
                <img src={props.src} alt={props.alt} className="lg:w-[700px] w-[450px] h-[250px]"/>
            </Link>
            <Link to={props.link}>
                <p className="text-xl text-center font-bold text-white whitespace-pre-line hover:text-dimeltro-light-gray">{props.title}</p>
            </Link>
        </div> 
    )
}

function FillServices() {
    const rows = []
    for (let counter = 0; counter < services.length; counter = counter+2)
    {
        rows.push(
        <div className="flex lg:flex-row flex-col w-full justify-between lg:items-start items-center gap-x-6 gap-y-8">
            <FillRows src={services[counter].src} alt={services[counter].alt} title={services[counter].title} link={services[counter].link}/>
            <FillRows src={services[counter+1].src} alt={services[counter+1].alt} title={services[counter+1].title} link={services[counter+1].link}/>
        </div>
        )
    }
    return rows
}

function ServicePage(props) {
    return (
    <div className="my-10 2xl:mx-48 xl:mx-32 md:mx-12 mx-2 min-h-[calc(100vh-31rem)]">
        <div className="flex justify-center text-3xl font-bold text-white py-6 bg-dimeltro-blue">
            <p className="text-center">{props.title}</p>
        </div>
        <img src={props.img} alt={props.alt} className="w-full lg:h-[500px] h-[300px]"/>
        <div className="flex lg:flex-row flex-col py-6 bg-dimeltro-light-gray items-center lg:gap-y-0 gap-y-4">
            <div className="text-dimeltro-dark-gray lg:w-4/6 w-auto py-4 px-8">
                <p className="text-2xl italic whitespace-pre-line">{props.text}</p>
            </div>
            <div className="flex justify-center lg:w-2/6 w-auto">
                <Link to="/Contacto">
                    <img src='/contact-us-home.png' alt="Imagen de contáctenos"/>
                </Link>
            </div> 
        </div>
    </div>
    )    
}

export {FillRows, FillServices, ServicePage};