import React from 'react';
import { Link } from 'react-router-dom';
import { PhoneIcon, EnvelopeOpenIcon } from '@heroicons/react/24/solid'
import { BsWhatsapp  } from 'react-icons/bs'


const ContactBar = () => {
    return (
        <div className='shadow-md w-full top-0 left-0'>
            <div className='flex lg:flex-row flex-col items-center justify-between bg-dimeltro-light-gray py-4 2xl:px-48 xl:px-32 px-2 text-xl text-dimeltro-blue font-semibold font-sans gap-2'>
                <Link to='tel:+506-22448282' className='flex gap-x-2 items-end hover:text-white'>
                    <PhoneIcon className='w-10 h-10'></PhoneIcon>
                    <p>(506) 22448282</p>
                </Link>
                <Link to='tel:+506-83727074' className='flex gap-x-2 items-end hover:text-white'>
                    <BsWhatsapp className='w-10 h-10'></BsWhatsapp>
                    <p>(506) 83727074</p>
                </Link>
                <Link to='mailTo:jc.dimeltro@gmail.com' className='flex gap-x-2 items-end hover:text-white'>
                    <EnvelopeOpenIcon className='w-10 h-10'></EnvelopeOpenIcon>
                    <p>jc.dimeltro@gmail.com</p>
                </Link>
            </div>
        </div>
    );
};

export default ContactBar;