export default function SobreNosotros() {
  return (
    <div className="min-h-[calc(100vh-27rem)]">
      <div className="row-3">
        <div className="flex sxl:flex-row flex-col-reverse pt-10 2xl:px-48 xl:px-32 md:px-12 px-2 2xl:gap-x-10 sxl:gap-x-4 gap-x-2 mt-4">
            <div className="sxl:w-7/12">
              <div className="sxl:w-7/12 text-3xl sxl:mb-2 mb-0 hidden sxl:flex">
                <h2>¿Quienes somos?</h2>
              </div>
              <div className="text-dimeltro-dark-gray bg-dimeltro-light-gray xl:text-xl text-lg font-sans py-6 px-4">
                  <p className="italic">Somos una empresa fundada desde 1997 e inscrita en el CFIA como Consultora y Constructora desde el 2002.<br/><br/>
                  Somos una empresa Pyme especializada en la construcción, remodelación, diagnóstico y mantenimiento de instalaciones eléctricas, electromecánicas y solares, 
                  tanto a nivel comercial como industrial. Contamos con mano de obra calificada y ofrecemos equipos y materiales de calidad, debidamente certificados.<br/><br/>
                  Con más de 23 años en el mercado, nos hemos consolidado como una empresa confiable y comprometida con la satisfacción de nuestros clientes.</p>
                  <br/>
                  <p className="font-bold">En Dimeltro S.A. la calidad, el compromiso y la excelencia se unen para hacer realidad sus proyectos.</p>
              </div>
            </div>
            <img className="sxl:w-5/12 2xl:max-h-[450px] sxl:h-auto h-[250px] object-cover" src='/about-ingeniero.jpg' alt="Equipo montando un pararrayos"/>
            <div div className="sxl:w-7/12 sxl:hidden sxl:mt-0 mt-4 flex">
              <h2 className="text-3xl mt-4 mb-4">¿Quienes somos?</h2>
            </div>
        </div>
        
        <div className="flex sxl:flex-row flex-col pt-10 2xl:px-48 xl:px-32 md:px-12 px-2 2xl:gap-x-10 sxl:gap-x-4 gap-x-2">
          <div div className="sxl:w-7/12 sxl:hidden sxl:mt-4 mt-0 flex">
            <h2 className="text-3xl mt-4 mb-4">Valores</h2>
          </div>
          <img className="sxl:w-5/12 object-cover 2xl:max-h-[500px] sxl:h-auto h-[250px]" src='/equipo-pararrayos.jpg' alt="Equipo montando un pararrayos"/>
          <div className="sxl:w-7/12">
            <div className="sxl:w-7/12 text-3xl sxl:mb-2 mb-0 hidden sxl:flex">
              <h2>Valores</h2>
            </div>
            <div className="text-dimeltro-dark-gray bg-dimeltro-light-gray sxl:text-xl text-lg font-sans px-4 py-6">
                <p>Son nuestros valores fundamentales:</p><br/>
                <ul className="list-disc ml-6 sxl:pb-0 pb-4">
                  <li><b>Seguridad:</b> Hacemos de la seguridad un estilo de vida.</li>
                  <li><b>Integridad:</b> Actuamos con honestidad e integridad. Somos transparentes y justos.</li>
                  <li><b>Responsabilidad y calidad:</b> Garantizamos la responsabilidad y calidad de nuestro servicio y a cumplir con la normativa vigente en todo momento.</li>
                  <li><b>Servicio al cliente:</b> Nos adaptamos a las necesidades de nuestros clientes.</li>
                  <li><b>Innovación:</b> Nos mantenemos actualizados con las últimas tecnologías y buscamos soluciones innovadoras.</li>
                  <li><b>Trabajo en equipo:</b> promovemos la comunicación efectiva empresa - cliente para lograr objetivos comunes.</li>
                  <li><b>Sostenibilidad:</b> Buscamos prácticas y soluciones sostenibles y respetuosas con el medio ambiente.</li>
                </ul>
            </div>
          </div>
        </div>
        
        <div className="flex sxl:flex-row flex-col-reverse pt-10 2xl:px-48 xl:px-32 md:px-12 px-2 2xl:gap-x-10 sxl:gap-x-4 gap-x-2">
          <div className="sxl:w-7/12">
            <div className="sxl:w-7/12 text-3xl sxl:mb-2 mb-0 hidden sxl:flex">
              <h2 className="">Visión</h2>
            </div>
            <div className="text-dimeltro-dark-gray bg-dimeltro-light-gray sxl:text-xl text-lg font-sans px-4 py-6 object-cover">
              <p className="leading-8 italic">Nuestra visión es la de brindar a nuestros clientes un servicio de calidad, cumplir con nuestros compromisos de manera responsable, 
                mantenernos siempre actualizados con las normativas vigentes y con la tecnología actual.<br/><br/> Nos esforzamos por ofrecer soluciones innovadoras 
                que aseguren la satisfacción de nuestros clientes. Trabajamos con dedicación para ser reconocidos como un referente en excelencia y 
                confiabilidad.</p>
            </div>
          </div>
          <img className="sxl:w-5/12 2xl:max-h-[450px] sxl:h-auto h-[250px]" src='/equipo.jpg' alt="Equipo montando un pararrayos"/>
          <div div className="sxl:w-7/12 sxl:hidden sxl:mt-0 mt-4 flex">
            <h2 className="text-3xl mt-4 mb-4">Visión</h2>
          </div>
        </div>
      </div> 

      <div className="flex items-center justify-center w-full mt-6 gap-x-2">
        <div className="bg-dimeltro-yellow h-4 w-3/4"></div>
        <p className="text-2xl font-sans self-center font-bold">VENTAJAS</p>
        <img className='self-center sxl:flex hidden' src='/dimeltro-logo.png' alt='Dimeltro'/>
        <div className="bg-dimeltro-yellow h-4 w-3/4"></div>
      </div>
      
      <div className="flex sxl:flex-row flex-col col-2 items-center justify-around mt-6 mb-8 sxl:gap-y-0 gap-y-8">
        <div className="flex flex-col items-center">
          <img src='/about-calidad-precio.png' alt='Calidad-precio'/>
          <p className="text-2xl font-sans font-bold text-dimeltro-blue">CALIDAD-PRECIO</p>
        </div>
        <div className="flex flex-col items-center">
          <img src='/about-garantia.png' alt='Garantía'/>
          <p className="text-2xl font-sans font-bold text-dimeltro-blue">GARANTÍA</p>
        </div>
        <div className="flex flex-col items-center">
          <img src='/about-proyectos-a-medida.png' alt='Proyectos a medida'/>
          <p className="text-2xl font-sans font-bold text-dimeltro-blue">PROYECTOS A MEDIDA</p>
        </div>
        <div className="flex flex-col items-center">
          <img src='/about-responsabilidad.png' alt='Responsabilidad'/>
          <p className="text-2xl font-sans font-bold text-dimeltro-blue">RESPONSABILIDAD</p>
        </div>
      </div>
    </div> 
  )
}