import React from 'react';
import { ServicePage } from '../../../Components/ServicesComponents';

export default function ServiciosMedicionDeCalidadDeEnergia() {

    const title = "MEDICIÓN DE CALIDAD DE ENERGÍA";
    const img = "/services-medicion-calidad-energia2.jpg";
    const alt = "Servicio de diagnóstico de medición de calidad de energía";
    const text = "Ofrecemos un servicio especializado en la medición de calidad de la energía eléctrica. Contamos con equipos y personal calificado para tomar lectura " + 
    "de la regulación del voltaje, desbalance de líneas, factor de potencia, THD (distorsión armónica total), entre otros aspectos relevantes." + 
    "Nuestro informe detallado le permitirá optimizar sus instalaciones, mejorar la eficiencia y prevenir problemas costosos.\n\n" +
    "¡Confíe en nosotros para asegurar un rendimiento energético óptimo en sus operaciones!"

    return (
        <div className="Diseño Eléctrico y tramites de planos">
            <ServicePage title={title} img={img} alt={alt} text={text}/>
        </div>
    )
}