import React from 'react';
import { Link } from 'react-router-dom';
import { PhoneIcon, EnvelopeOpenIcon } from '@heroicons/react/24/solid'
import { BsWhatsapp  } from 'react-icons/bs'


const Footer = () => {
    return (
        <div className='shadow-md w-full bg-black'>
            <div className='flex lg:flex-row flex-col lg:gap-y-0 gap-y-8 lg:items-start items-center justify-around py-4 lg:mx-12 px-2 text-xl text-white
            font-semibold font-sans'>
                <div className='flex flex-col items-center'>
                    <img className='mb-1' src='/dimeltro-logo-circle-only.png' alt='Dimeltro'/>
                    <p className='text-2xl font-black'>DIMELTRO S.A.</p>
                    <p>Instalaciones Eléctricas,</p>
                    <p>Electromecánicas y solares</p>
                </div>
                <div className='flex flex-col items-center'>
                    <p>DIMELTRO S.A., empresa inscrita</p>
                    <p>en el CFIA desde el año 2002.</p>
                    <img className='mt-4' src='/pymeCR.png' alt='Dimeltro'/>
                </div>
                <div className='flex flex-col items-start gap-y-3'>
                    <p className='text-2xl'>Contacte con nosotros:</p>
                    <Link to='tel:+506-22448282' className='flex gap-x-2 hover:text-dimeltro-dark-gray'>
                        <PhoneIcon className='w-10 h-10'></PhoneIcon>
                        <p>(506) 22448282</p>
                    </Link>
                    <Link to='tel:+506-83727074' className='flex gap-x-2 hover:text-dimeltro-dark-gray'>
                        <BsWhatsapp className='w-10 h-10'></BsWhatsapp>
                        <p>(506) 83727074</p>
                    </Link>
                    <Link to='mailto:jc.dimeltro@gmail.com' className='flex gap-x-2 hover:text-dimeltro-dark-gray'>
                        <EnvelopeOpenIcon className='w-10 h-10'></EnvelopeOpenIcon>
                        <p>jc.dimeltro@gmail.com</p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;