import React from 'react';
import { ServicePage } from '../../../Components/ServicesComponents';

export default function ServiciosConstMantElectricaElectromecanica() {

    const title = "CONSTRUCCIÓN Y MANTENIMIENTO DE INSTALACIONES ELÉCTRICAS Y ELECTROMECÁNICAS COMERCIALES E INDUSTRIALES";
    const img = "/services-cons-y-mantenimiento.jpg";
    const alt = "Servicio de construcción y mantenimiento de instalaciones eléctricas y electromecánicas comerciales e industriales";
    const text = "Somos especialistas en la construcción y mantenimiento de instalaciones eléctricas y electromecánicas para proyectos comerciales e industriales. " + 
    "Nuestros servicios incluyen: montajes eléctricos de acometidas, transformadores secos, canalizaciones en tubería EMT, IMC, PVC, ductos o canastas, " +
    "tableros y circuitos ramales.\n\n" +
    "Nuestro equipo de trabajo se esfuerza constantemente por superar las expectativas de nuestros clientes y garantizar el éxito a largo plazo de " +
    "sus instalaciones eléctricas y electromecánicas."

    return (
        <div className="Construcción y mantenimiento de instalaciones eléctricas y electromecánicas comerciales e industriales">
            <ServicePage title={title} img={img} alt={alt} text={text}/>
        </div>
    )
}
