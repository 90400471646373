import React from "react";
import { FillProjects } from "../../Components/ProjectsComponents";

export default function Proyectos() {
    return (
        <>
            <div className='my-4 2xl:mx-48 xl:mx-32 md:mx-12 mx-2 text-lg text-dimeltro-dark-gray gap-2'>
                <div className="flex justify-center mt-10">
                    <img className='mt-4 w-full lg:h-[600px] h-[350px]' src='/projects-portada.jpg' alt="Portada de proyectos"/>
                </div>
                <div className="py-8 px-6 text-2xl text-dimeltro-dark-gray bg-dimeltro-light-gray font-sans">
                    <p>Los <b>PROYECTOS</b> que hemos realizado en <b>construcción, remodelación, diagnóstico y mantenimiento de instalaciones eléctricas, 
                    electromecánicas y solares a clientes exigentes nos respalda.</b></p>
                    <br/>
                    <p className="italic">Durante más de 23 años, en DIMELTRO S.A. hemos realizados proyectos desde los más complejos en ingeniería eléctrica, 
                    hasta los más pequeños y sencillos trabajos tanto a nivel comercial como industrial. Le ofrecemos toda nuestra experiencia como empresa 
                    constructora y consultora para que los sistemas eléctricos, electromecánicos y solares de su negocio sean óptimos en operatividad y consumo 
                    energético, con la garantía del cumplimiento de la normativa vigente.</p>
                    <div className="flex flex-col my-8 gap-y-8">
                        <FillProjects/>
                    </div>
                </div>
            </div>
        </>
    )
}