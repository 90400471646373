import React from 'react';
import { ServicePage } from '../../../Components/ServicesComponents';

export default function ServiciosDiagnosticoDeInstalacionesElectricas() {

    const title = "DIAGNÓSTICO DE INSTALACIONES ELÉCTRICAS";
    const img = "/services-diagnostico-electrico.jpg";
    const alt = "Servicio de diagnóstico de instalaciones eléctricas";
    const text = "Le proporcionamos un análisis exhaustivo y preciso de sus instalaciones eléctricas, identificando posibles fallas, puntos débiles y áreas de mejora." + 
    "Contamos con profesionales altamente capacitados y herramientas de vanguardia para llevar a cabo evaluaciones meticulosas.\n\n" + 
    "Proteja su inversión y garantice la eficiencia, confiabilidad y seguridad de sus instalaciones eléctricas con nuestro servicio de diagnóstico profesional."

    return (
        <div className="Diseño Eléctrico y tramites de planos">
            <ServicePage title={title} img={img} alt={alt} text={text}/>
        </div>
    )
}