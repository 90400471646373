import { Link } from "react-router-dom";

export default function Pagina404() {
    return (
        <div className="2xl:mx-48 xl:mx-32 md:mx-12 mx-2 min-h-[calc(100vh-30rem)]">
            <div className="flex flex-col justify-between items-center my-16 gap-y-20">
                <p className="text-4xl text-center">¡Parece que te has perdido!</p>
                <div className="flex flex-col gap-y-6">
                    <p className="text-2xl text-center">La página que estás buscando no existe o ya no se encuentra disponible</p>
                    <p className="text-3xl text-center">ERROR 404</p>
                </div>
                <div className="flex justify-center">
                    <Link to="/" className="text-center text-2xl bg-dimeltro-blue p-4 text-white"> 
                        Volver a la página de inicio
                    </Link>
                </div>
            </div>
        </div>
    );
}