import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Home";
import SobreNosotros from "../SobreNosotros";
import Servicios from "../Servicios";
import ServiciosDiseñoElectricoTramitesPlanos from "../Servicios/Diseño Eléctrico y tramites de planos";
import ServiciosDiagnosticoDeInstalacionesElectricas from "../Servicios/Diagnostico de instalaciones electricas";
import ServiciosMedicionDeCalidadDeEnergia from "../Servicios/Medicion de Calidad de Energia";
import ServiciosPararrayosMallasDePuestaTierra from "../Servicios/Pararrayos y mallas de puesta a tierra";
import ServiciosTorresDeComunicaciones from "../Servicios/Torres de comunicaciones";
import ServiciosConstMantElectricaElectromecanica from "../Servicios/Construcción y mantenimiento de instalaciones electricas y electromecanicas";
import ServiciosCargadoresVehiculosElectricos from "../Servicios/Cargadores de vehiculos electricos";
import ServiciosSistemasFotovoltaicos from "../Servicios/Sistemas fotovoltaicos";
import Proyectos from "../Proyectos";
import Contacto from "../Contacto";
import ContactBar from "../../Components/contactBar";
import Navbar5 from "../../Components/navBar copy 2";
import Footer from "../../Components/Footer";
import Pagina404 from "../Pagina404/pagina404";
import { ScrollToTop } from "../../Components/EffectsComponents";

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop></ScrollToTop>
        <ContactBar></ContactBar>
          <Navbar5></Navbar5>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/SobreNosotros" element={<SobreNosotros />} />
              <Route path="/Servicios" element={<Servicios />} />
              <Route path="/Servicios/Diseno-electrico-tramites-planos" element={<ServiciosDiseñoElectricoTramitesPlanos />} />
              <Route path="/Servicios/Diagnostico-instalaciones-eléctricas" element={<ServiciosDiagnosticoDeInstalacionesElectricas />} />
              <Route path="/Servicios/Medicion-calidad-energia" element={<ServiciosMedicionDeCalidadDeEnergia />} />
              <Route path="/Servicios/Pararrayos-mallas-puesta-tierra" element={<ServiciosPararrayosMallasDePuestaTierra />} />
              <Route path="/Servicios/Torres-comunicaciones" element={<ServiciosTorresDeComunicaciones />} />
              <Route path="/Servicios/Construccion-mantenimiento-instalaciones-electricas-electromecanicas" element={<ServiciosConstMantElectricaElectromecanica />} />
              <Route path="/Servicios/Cargadores-vehiculos-electricos" element={<ServiciosCargadoresVehiculosElectricos />} />
              <Route path="/Servicios/Sistemas-fotovoltaicos" element={<ServiciosSistemasFotovoltaicos />} />
              <Route path="/Proyectos" element={<Proyectos />} />
              <Route path="/Contacto" element={<Contacto />} />
              <Route path="*" element={<Pagina404 />} />
            </Routes>
        <Footer></Footer>
    </BrowserRouter>
  );
}