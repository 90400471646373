import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { ChevronDoubleDownIcon, Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/24/solid'

const Navbar5 = () => {

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    let [isOpenServices, setIsOpenServices] = useState(false);

    const toggleNavbar = () => {
        setIsOpenMenu(!isOpenMenu);
    };

    const closeNavbar = () => {
        setIsOpenMenu(false)
    }
    
    const toggleDropdown = () => {
        setIsOpenServices(!isOpenServices);
    };

    const openDropdown = () => {
        if (window.innerWidth > 1024) {
            setIsOpenServices(true);
        }
        else {
            setIsOpenServices(false);
        }
    };

    const closeDropdown = () => {
        setIsOpenServices(false);
    };

    
    let linksOne = [
        {name:"HOME",link:"/"},
        {name:"SOBRE NOSOTROS",link:"/SobreNosotros"},
    ];

    let linksTwo = [
        {name:"PROYECTOS",link:"/Proyectos"},
        {name:"CONTACTO",link:"/Contacto"}
    ];

    let services = [
        {name:"Diseño Eléctrico y trámites de planos", link:"/Servicios/Diseno-electrico-tramites-planos"},
        {name:"Diagnóstico de instalaciones eléctricas", link:"/Servicios/Diagnostico-instalaciones-eléctricas"},
        {name:"Medición de Calidad de Energía", link:"/Servicios/Medicion-calidad-energia"},
        {name:"Pararrayos y mallas de puesta a tierra", link:"/Servicios/Pararrayos-mallas-puesta-tierra"},
        {name:"Torres de Comunicaciones", link:"/Servicios/Torres-comunicaciones"},
        {name:"Instalaciones eléctricas y electromecánicas", link:"/Servicios/Construccion-mantenimiento-instalaciones-electricas-electromecanicas"},
        {name:"Cargadores de vehículo eléctricos", link:"/Servicios/Cargadores-vehiculos-electricos"},
        {name:"Sistemas fotovoltaicos", link:"/Servicios/Sistemas-fotovoltaicos"}
    ];

    return (
        <div className='shadow-md w-full top-0 left-0 sticky z-[3]'>
            <div className='lg:flex items-center bg-white py-4 2xl:px-48 xl:px-32 md:px-12 px-2 text-lg text-dimeltro-dark-gray font-semibold font-sans gap-2'>
                {/* logo section */}
                <Link to="/" className='font-bold cursor-pointer flex items-center lg:mr-8 lg:w-auto w-[125px]'>
                    <img src='/dimeltro-logo.png' alt='Dimeltro' className='lg:h-auto h-10'/>
                </Link>
                {/* Menu icon */}
                <div onClick={toggleNavbar} className='absolute right-8 top-6 cursor-pointer lg:hidden w-7 h-7'>
                    {
                        isOpenMenu ? <XMarkIcon/> : <Bars3BottomRightIcon />
                    }
                </div>
                {/* linke items */}
                <div className={`lg:flex lg:flex-row justify-end 2xl:gap-x-20 lg:gap-x-10 gap-x-0 lg:items-center lg:pb-0 lg:pl-0 pb-2 lg:pt-0 pt-4 md:pl-9 pl-2 lg:static 
                bg-white lg:z-auto z-[-1] left-0 w-full absolute transition-all duration-500 ease-in ${isOpenMenu ? 'flex flex-col' : 'hidden'}`}>
                    {
                        linksOne.map((link) => (
                            <Link to={link.link} onClick={closeNavbar} className='block hover:bg-dimeltro-dark-gray hover:text-dimeltro-light-gray mx-4 my-2'>
                                {link.name}
                            </Link>
                        ))
                    }
                    <div className="relative group" onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
                    {/* Servicios con lista desplegable */}
                        <div className='flex items-center'>
                            <Link to="/Servicios" onClick={closeNavbar} className='block hover:bg-dimeltro-dark-gray hover:text-dimeltro-light-gray mx-4 my-2'>
                                SERVICIOS
                            </Link>
                            <ChevronDoubleDownIcon className='absolute right-2 lg:hidden w-4 h-4' onClick={toggleDropdown}></ChevronDoubleDownIcon>
                        </div>
                        <div className={`lg:absolute lg:pl-0 md:pl-10 pl-2 relative z-10 bg-white w-full rounded-md lg:w-72 ${isOpenServices ? 'block' : 'hidden'}`}>
                        {
                            services.map((service) => (
                                <Link to={service.link} onClick={closeNavbar} className='block hover:bg-dimeltro-dark-gray hover:text-dimeltro-light-gray mx-4 my-2'>
                                    {service.name}
                                </Link>
                            ))
                        }
                        </div>
                    </div>
                    {
                        linksTwo.map((link) => (
                            <Link to={link.link} onClick={closeNavbar} className='block hover:bg-dimeltro-dark-gray hover:text-dimeltro-light-gray mx-4 my-2'>
                                {link.name}
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Navbar5;