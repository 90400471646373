import React from 'react';
import {FillServices } from '../../Components/ServicesComponents';

export default function Servicios() {
    return (
      <>
        <div className="py-10 2xl:px-48 xl:px-32 md:px-12 px-2 bg-dimeltro-blue min-h-[calc(100vh-26rem)]">
            <div className="flex justify-center text-3xl font-bold text-white my-6">
                <p>SERVICIOS ELÉCTRICOS, ELECTROMECÁNICOS Y SOLARES</p>
            </div>
            <div className="bg-dimeltro-yellow h-4 w-full"></div>
            <div className="flex flex-col my-8 gap-y-8">
                <FillServices/>
            </div>
        </div>
    </>
    )
}