import React from 'react';
import { ServicePage } from '../../../Components/ServicesComponents';

export default function ServiciosCargadoresVehiculosElectricos() {

    const title = "CARGADORES DE VEHÍCULO ELÉCTRICOS: TRÁMITES, SUMINISTRO E INSTALACIÓN";
    const img = "/services-cargador-electrico.jpg";
    const alt = "Servicio de cargadores de vehículo eléctricos: trámites, suministro e instalación";
    const text = "Nos encargamos de todos los trámites y permisos necesarios, suministramos equipos de alta calidad y realizamos una instalación profesional, " +
    "cumpliendo con las normativas legales y requerimientos técnicos vigentes.\n\n " +
    "Nos enorgullece contribuir a la movilidad sostenible y al cuidado del medio ambiente. Nuestros servicios de cargadores para vehículos eléctricos buscan " +
    "fomentar la adopción de energías limpias y reducir la huella de carbono.\n\n" +
    "Estamos comprometidos en brindarle a nuestros clientes soluciones eficientes y sostenibles para impulsar su movilidad eléctrica."

    return (
        <div className="Diseño Eléctrico y tramites de planos">
            <ServicePage title={title} img={img} alt={alt} text={text}/>
        </div>
    )
}
