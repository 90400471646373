import React from 'react';
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function Home() {
  return (
    <div className="App">
      <div className="2xl:mx-48 xl:mx-32 md:mx-12 mx-2 min-h-[calc(100vh-29rem)]">
        <div div className="flex justify-center mt-10">
          <Carousel showThumbs={false} autoPlay={true} interval={3000} infiniteLoop={true}>
            <div>
              <img src='/services-diagnostico-electrico.jpg' alt="carrusel 1" className='lg:h-[550px] h-[350px]'/>
            </div>
            <div>
              <img src='/services-torre-comunicaciones.jpg' alt="carrusel 2" className='lg:h-[550px] h-[350px]'/>
            </div>
            <div>
              <img src='/projects-diseno-tramites-planos.jpg' alt="carrusel 3" className='lg:h-[550px] h-[350px]'/>
            </div>
            <div>
              <img src='/services-sistemas-fotovoltaicos.jpg' alt='carrusel 4' className='lg:h-[550px] h-[350px]'/>
            </div>
          </Carousel>
        </div>
        <div className="flex lg:flex-row flex-col my-6 bg-dimeltro-light-gray items-center lg:gap-y-0 gap-y-4">
          <div className="text-dimeltro-dark-gray lg:w-4/6 w-auto p-4">
            <p className="text-xl italic"> En <b>DIMELTRO S.A.</b>, nos especializamos en la <b>construcción, remodelación, diagnóstico y mantenimiento de instalaciones 
              eléctricas, electromecánicas y solares</b> tanto a nivel comercial como industrial.<br/><br/>Nos enfocamos en brindar soluciones a la medida por lo que nos 
              adaptarnos a las necesidades de cada cliente.<br/><br/>Como empresa consultora y constructora con más de 23 años de experiencia, en DIMELTRO S.A. nos enorgullece 
              ofrecer una muy buena relación calidad-precio del mercado. Nos comprometemos a garantizar la responsabilidad y calidad de nuestro servicio y a cumplir con la 
              normativa vigente en todo momento.</p>
            <br/>
            <p className="text-2xl">Contáctenos y descubra cómo podemos ayudarle en sus proyectos eléctricos, electromecánicos y solares.</p>
          </div>
          <div className="flex justify-center lg:w-2/6 w-auto">
            <Link to="/Contacto">
              <img src='/contact-us-home.png' alt="Imagen de contáctenos"/>
            </Link>
          </div> 
        </div>
        <p className="bg-yellow-400 text-center text-2xl text-dimeltro-dark-gray font-bold">Nuestros socios comerciales confían en nuestra visión y experiencia</p>
        <div className="flex lg:flex-row flex-col items-center justify-between my-6 text-xl text-dimeltro-blue font-semibold font-sans lg:gap-y-0 gap-y-12">
          <img src='/ProtelCr-logo.png' alt="Logo de ProtelCr" loading="lazy" className='w-50 h-50'/>
          <img src='/TesMayoreo-logo.png' alt="Logo de ProtelCr" loading="lazy" className='w-50 h-50'/>
          <img src='/maz-logo.png' alt="Logo de ProtelCr" loading="lazy" className='w-50 h-50'/>
        </div>
      </div>
    </div>
  );
}