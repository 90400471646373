import React from 'react';
import { Link } from 'react-router-dom';
import { PhoneIcon, EnvelopeOpenIcon } from '@heroicons/react/24/solid'
import { BsWhatsapp, BsLinkedin } from 'react-icons/bs'

export default function Contacto() {
    return (
    <div className="Contacto">
        <div className="2xl:mx-48 xl:mx-32 md:mx-12 mx-2 min-h-[calc(100vh-29rem)]">
            <div className="flex justify-center mt-10">
                <img className='my-4 w-full lg:h-[550px] h-[250px]' src='/contact-main.jpg' alt="Portada de contacto"/>
            </div>
            <p className="bg-dimeltro-yellow w-min text-3xl p-2 font-bold">CONTACTO</p>
            <div className="text-2xl font-sans my-6 text-dimeltro-dark-gray">
                <p>Somos su especialista en <b>sistemas eléctricos, electromecánicos y solares:</b></p><br/>
                <ul className="list-disc ml-6 sxl:pb-0 pb-4">
                    <li>Diseño eléctrico y trámites de planos visados en el CFIA.</li>
                    <li>Diagnóstico de instalaciones eléctricas.</li>
                    <li>Medición de calidad de energía.</li>
                    <li>Diagnóstico de pararrayos y mallas de puesta a tierra.</li>
                    <li>Mantenimiento de torres de comunicaciones.</li>
                    <li>Diseño y construcción de sistemas fotovoltaicos.</li>
                    <li>Construcción, remodelación y mantenimiento de instalaciones eléctricas y electromecánicas tanto a nivel comercial como industrial.</li>
                    <li>Diseño, construcción y mantenimiento de sistemas solares.</li>
                </ul>
                <br/>
                <p className="font-bold italic">Consúltenos sin compromiso y descubra cómo podemos ayudarle en sus proyectos.</p>
            </div>
            <div className="flex lg:flex-row flex-col items-center justify-between my-4 text-xl text-dimeltro-blue font-semibold font-sans gap-2">
                <Link to='tel:+506-22448282' className="flex flex-col gap-y-2 items-center hover:text-dimeltro-light-gray">
                    <PhoneIcon className='w-10 h-10'></PhoneIcon>
                    <p>(506) 22448282</p>
                </Link>
                <Link to='tel:+506-83727074' className="flex flex-col gap-y-2 items-center hover:text-dimeltro-light-gray">
                    <BsWhatsapp className='w-10 h-10'></BsWhatsapp>
                    <p>(506) 83727074</p>
                </Link>
                <Link to='mailto:jc.dimeltro@gmail.com' className="flex flex-col gap-y-2 items-center hover:text-dimeltro-light-gray">
                    <EnvelopeOpenIcon className='w-10 h-10'></EnvelopeOpenIcon>
                    <p>jc.dimeltro@gmail.com</p>
                </Link>
                <Link to='https://www.linkedin.com/in/jose-rafael-claramunt-garro-814397119/' className="flex flex-col gap-y-2 items-center hover:text-dimeltro-light-gray">
                    <BsLinkedin className='w-10 h-10 hover:text-dimeltro-light-gray'></BsLinkedin>
                    <p>LinkedIn</p>
                </Link>
            </div>
            <br/>
        </div>
    </div>
    );
  }