import React from 'react';
import { ServicePage } from '../../../Components/ServicesComponents';

export default function ServiciosDiseñoElectricoTramitesPlanos() {

    const title = "DISEÑO ELÉCTRICO Y TRÁMITES DE PLANOS VISADOS EN EL CFIA";
    const img = "/projects-diseno-tramites-planos.jpg";
    const alt = "Servicio de diseño eléctrico y trámites de planos visados en el CFIA";
    const text = "Nuestro servicio especializado ofrece el diseño eléctrico y la creación de planos visados por el CFIA para sus proyectos de instalaciones eléctricas.\n\n" + 
    "Nuestro equipo de trabajo diseña instalaciones seguras, confiables y eficientes, seleccionando componentes adecuados y tecnología avanzada. Los planos visados aseguran " +
    "el cumplimiento normativo vigente.\n\n" +
    "Trabajamos estrechamente con nuestros clientes, adaptándonos a sus requerimientos y plazos. ¡Déjenos hacer realidad su proyecto eléctrico de manera exitosa!"

    return (
        <div className="Diseño Eléctrico y tramites de planos">
            <ServicePage title={title} img={img} alt={alt} text={text}/>
        </div>
    )
}