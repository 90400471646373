import React from 'react';
import { ServicePage } from '../../../Components/ServicesComponents';

export default function ServiciosSistemasFotovoltaicos() {

    const title = "DISEÑO Y CONSTRUCCIÓN DE SISTEMAS FOTOVOLTAICOS";
    const img = "/services-sistemas-fotovoltaicos.jpg";
    const alt = "Servicio de diseño y construcción de sistemas fotovoltaicos";
    const text = "Ofrecemos servicios especializados en el diseño y construcción de sistemas fotovoltaicos a la medida. Diseñamos y construímos sistemas fotovoltaicos aislados " +
    "o conectados a la red, también diseñamos y construímos sistemas fotovoltaicos que se complementen con la red eléctrica pública para contar con respaldo eléctrico " +
    "en caso de falla de la red pública.\n\n" +
    "Nos enorgullece promover el uso de energía solar como una solución sostenible y respetuosa con el medio ambiente. Nuestros sistemas fotovoltaicos están diseñados " +
    "para maximizar el aprovechamiento de la energía solar y reducir la huella de carbono.\n\n" +
    "Desde el diseño hasta la instalación, aseguramos la eficiencia y confiabilidad de cada proyecto."

    return (
        <div className="Diseño Eléctrico y tramites de planos">
            <ServicePage title={title} img={img} alt={alt} text={text}/>
        </div>
    )
}
