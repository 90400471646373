import React from "react";

const projects = [
    {src:'/projects-diseno-tramites-planos.jpg', alt:'Servicio de diseño eléctrico y trámites de planos visados en el CFIA', 
    title:'Diseño eléctrico y trámites de planos visados en el Colegio Federado de Ingenieros y Arquitectos:', 
    desc: 'Municipalidad de Tibás, San José. Municipalidad de Acosta, San José. Concejo Municipal de Colorado, Guanacaste.'},

    {src:'/services-diagnostico-electrico.jpg', alt:'Servicio de diagnóstico de instalaciones eléctricas', title:'Medición de aislamiento.',
    desc:'Tablero principal en Planta de Grau, zona franca de Cartago.'},

    {src:'/projects-medicion-termografica.jpg', alt:'Proyecto de medición termográfica', title:'Pruebas con Cámara Termográfica.',
    desc:'East West Manufacturing Costa Rica, S.R.L. Zona Franca Bes, Alajuela.'},

    {src:'/projects-montaje-equipos-Qorvo-Zona-Franca-Metropolitana.jpg', alt:'Proyecto de montaje equipos Qorvo Zona Franca Metropolitana',
    title:'Montaje de Equipo.', desc:'En Cuarto Limpio de QORVO COSTA RICA S.R.L. Zona Franca Metropolitana.'},

    {src:'/projects-canalizaciones-subterranias.jpg', alt:'Proyecto de canalizaciones subterranias', title:'Canalizaciones Subterráneas.', 
    desc:'Centro Comercial Plaza San Francisco. Heredia.'},

    {src:'/projects-instalacion-canasta-aerea-Macopa.jpg', alt:'Proyecto de instalación de canasta aerea Macopa', title:'Canalizaciones Expuestas.', 
    desc:'MACOPA S.A. Calle Blancos, Guadalupe, San José.'},

    {src:'/projects-construccion-fosa-transformadores.jpg', alt:'Proyecto de construccion fosa transformadores', title: 'Obra Civil Fosa de Transformadores.', 
    desc:'Centro Comercial Plaza San Francisco. Heredia.'},

    {src:'/projects-montaje-transformador-seco.jpg', alt:'Proyecto de montaje de transformador seco', title:'Montaje de Transformador.', 
    desc:'Centro Comercial Plaza San Francisco. Heredia.'},

    {src:'/projects-diagnostico-pararrayos.jpg', alt:'Proyecto de diagnostico de pararrayos', title:'Diagnóstico de Pararrayos.', 
    desc:'Parque Eólico Movasa, Guanacaste.'},

    {src:'/projects-montaje-pararrayo-Chiquita-Brand-Changuinola-Panama.jpg', alt:'Proyecto de montaje de pararrayo en Chiquita Brand Changuinola Panamá', 
    title:'Instalación de Pararrayos.', desc:'Chiquita Brands International. Changuinola, Panamá.'},

    {src:'/projects-instalacion-electrica-iluminacion.jpg', alt:'Proyecto de instalación Eléctrica e Iluminación en el Concejo Municipal de Colorado', 
    title:'Instalación Eléctrica e Iluminación.', desc:'Salón Comunal de San Buenaventura. Concejo Municipal de Colorado. Guanacaste.'},

    {src:'/projects-postes-iluminacion.jpg', alt:'Proyecto de montaje de postes de iluminación', title:'Montaje de Postes de Iluminación.', 
    desc:'Plaza Los Mangos. Municipalidad de Santa Cruz. Guanacaste.'},

    {src:'/services-torre-comunicaciones.jpg', alt:'Servicio de torre de comunicaciones', title:'Mantenimiento de Torre de Comunicación.', 
    desc:'Cruz Roja El Roble, Puntarenas.'},

    {src:'/projects-planos-fotovoltaicos.jpg', alt:'Servicio de diseño y construcción de sistemas fotovoltaicos', title:'Diseño de Sistema Foltovoltaico.', 
    desc:'Municipalidad de Tibás, San José.'}
]

function FillRows (props) {
    return (
        <div className="flex flex-col items-center gap-y-4 lg:w-[700px] w-full mx-6">
            <img src={props.src} alt={props.alt} loading="lazy" className="lg:w-[700px] w-full h-[300px]"/>
            <p className="text-2xl text-center text-dimeltro-dark-gray"><b>{props.title}</b> {props.desc}</p>
        </div> 
    )
}

function FillProjects() {
    const rows = []
    for (let counter = 0; counter < projects.length; counter = counter+2)
    {
        rows.push(
        <div className="flex lg:flex-row flex-col w-full justify-between lg:items-start items-center gap-x-6 gap-y-8">
            <FillRows src={projects[counter].src} alt={projects[counter].alt} title={projects[counter].title} desc={projects[counter].desc}/>
            <FillRows src={projects[counter+1].src} alt={projects[counter+1].alt} title={projects[counter+1].title} desc={projects[counter+1].desc}/>
        </div>
        )
    }
    return rows
}

export {FillProjects};