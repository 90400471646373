import React from 'react';
import { ServicePage } from '../../../Components/ServicesComponents';

export default function ServiciosPararrayosMallasDePuestaTierra() {

    const title = "DIAGNÓSTICO DE PARARRAYOS Y MALLAS DE PUESTA A TIERRA";
    const img = "/projects-diagnostico-pararrayos.jpg";
    const alt = "Servicio de diagnóstico de pararrayos y mallas de puesta a tierra";
    const text = "Realizamos diagnósticos y mediciones de mallas de puesta a tierra, así como la comprobación del estado de los pararrayos y sus componentes para "+
    "asegurar su óptimo funcionamiento, además efectuamos las evaluaciones necesarias de todos los componentes del sistema de protección eléctrica para detectar " +
    "posibles deficiencias.\n\n" +
    "Nuestro equipo de trabajo está listo para brindarle soluciones a medida y asesoramiento personalizado. No dude en comunicarse con nosotros para obtener más " +
    "información sobre nuestros servicios."

    return (
        <div className="Diseño Eléctrico y tramites de planos">
            <ServicePage title={title} img={img} alt={alt} text={text}/>
        </div>
    )
}
