import React from 'react';
import { ServicePage } from '../../../Components/ServicesComponents';

export default function ServiciosTorresDeComunicaciones() {

    const title = "MANTENIMIENTO DE TORRES DE COMUNICACIONES";
    const img = "/services-torre-comunicaciones.jpg";
    const alt = "servicio de mantenimiento de torres de comunicaciones";
    const text = "Ofrecemos servicios integrales de mantenimiento para torres de comunicaciones, que incluyen pintura, mejoras estructurales e instalación de equipos. " +
    "Nuestro equipo altamente capacitado garantiza un óptimo rendimiento y seguridad de las torres, mejorando la calidad de las comunicaciones.\n\n" +
    "¡Nuestro compromiso es ofrecerle servicios de calidad!"

    return (
        <div className="Diseño Eléctrico y tramites de planos">
            <ServicePage title={title} img={img} alt={alt} text={text}/>
        </div>
    )
}
